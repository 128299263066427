import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { AddressUtilsService } from 'src/app/shared/services/address-utils.service';
import type { SubscriptionEstimateItem, SubscriptionUpdateItem } from '../../billing/services/subscription';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BillingApiService extends ApiService<any> {

  protected APIUrl = environment.SPRING_STORE_ENDPOINT_URL + this.getResourceUrl();

  private INVOICE_WRITABLE_FIELDS = [
    'poNumber'
  ];

  constructor(
    protected httpClient: HttpClient,
    protected companyStateService: CompanyStateService,
    private addressUtils: AddressUtilsService) {
      super(httpClient, companyStateService);
    }

    getResourceUrl(): string {
      return '/v0.01';
    }
  
  getSubscriptions(search, cursor?) {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId())
      .set('cursor', cursor || '')
      .set('count', search.count ? search.count.toString() : '');

    console.debug('Store integrations/subscriptions/list called with', params);

    return firstValueFrom(this.requestAsObservable('', 'GET', 'integrations/subscriptions/list', params));
  }

  getSubscription(subscriptionId: string, withScheduledChanges?: boolean): Promise<any> {
    const params = new HttpParams()
      .set('subscriptionId', subscriptionId)
      .set('companyId', this.companyStateService.getSelectedCompanyId())
      .set('withScheduledChanges', !!withScheduledChanges);

    console.debug('Store integrations/subscription/get called with', params);

    return firstValueFrom(this.requestAsObservable('', 'GET', 'integrations/subscription', params));
  }

  estimateSubscriptionUpdate(items: SubscriptionEstimateItem[], couponCode: string, endOfTerm: boolean): Promise<any> {
    const billingAddress = this.addressUtils.copyAddress(this.companyStateService.getCopyOfSelectedCompany());
    const jsonData = {
      billTo: billingAddress,
      shipTo: billingAddress,
      couponCode: couponCode,
      endOfTerm: endOfTerm,
      items
    };
    const params = new HttpParams()
      .set('jsonData', JSON.stringify(jsonData));

    console.debug('Store purchase/estimate called with', jsonData);

    return firstValueFrom(this.requestAsObservable('', 'GET', 'purchase/estimate', params));
  }

  prepareUpdateSubscription(items: SubscriptionUpdateItem[], couponCode: string, endOfTerm: boolean): Promise<any> {
    const billingAddress = this.addressUtils.copyAddress(this.companyStateService.getCopyOfSelectedCompany());
    const jsonData = {
      billTo: billingAddress,
      shipTo: billingAddress,
      couponCode: couponCode,
      endOfTerm: endOfTerm,
      items
    };
    const params = new HttpParams()
      .set('jsonData', JSON.stringify(jsonData));

    console.debug('Store purchase/prepare called with', jsonData);

    return firstValueFrom(this.requestAsObservable('', 'POST', 'purchase/prepare', params));
  }

  updateSubscription(items: SubscriptionUpdateItem[], couponCode: string, endOfTerm: boolean, card: any): Promise<any> {
    const billingAddress = this.addressUtils.copyAddress(this.companyStateService.getCopyOfSelectedCompany());
    const multiple = items.filter((item) => item.changed).length > 1;
    const jsonData = {
      billTo: billingAddress,
      shipTo: billingAddress,
      couponCode: couponCode,
      endOfTerm: endOfTerm,
      card,
      items: items.map(({ changed, ...item }) => item),
      invoiceImmediately: !multiple,
      useUnbilledCharges: multiple
    };
    const params = new HttpParams()
      .set('jsonData', JSON.stringify(jsonData));

    console.debug('Store purchase/update called with', jsonData);

    return firstValueFrom(this.requestAsObservable('', 'POST', 'purchase/put', params));
  }

  changePoNumber(subscriptionId, poNumber) {
    const params = new HttpParams()
      .set('subscriptionId', subscriptionId)
      .set('poNumber', poNumber)
      .set('companyId', this.companyStateService.getSelectedCompanyId());

    console.debug('Store integrations/subscription/changePoNumber called with', params);

    return firstValueFrom(this.requestAsObservable('', 'PUT', 'integrations/subscription/changePoNumber', params));
  }

  changePaymentSource(subscriptionId, paymentSourceId) {
    const params = new HttpParams()
      .set('subscriptionId', subscriptionId)
      .set('paymentSourceId', paymentSourceId)
      .set('companyId', this.companyStateService.getSelectedCompanyId());

    console.debug('Store integrations/subscription/changePaymentSource called with', params);

    return firstValueFrom(this.requestAsObservable('', 'PUT', 'integrations/subscription/changePaymentSource', params));
  }

  changePaymentToInvoice(subscriptionId, poNumber) {
    const params = new HttpParams()
      .set('subscriptionId', subscriptionId)
      .set('poNumber', poNumber)
      .set('companyId', this.companyStateService.getSelectedCompanyId());

    console.debug('Store integrations/subscription/changePaymentToInvoice called with', params);

    return firstValueFrom(this.requestAsObservable('', 'PUT', 'integrations/subscription/changePaymentToInvoice', params));
  }

  getInvoices(search, cursor?) {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId())
      .set('cursor', cursor || '')
      .set('count', search.count ? search.count.toString() : '');

    console.debug('Store integrations/invoices/list called with', params);

    return firstValueFrom(this.requestAsObservable('', 'GET', 'integrations/invoices/list', params));
  }

  getUnpaidInvoices(search, cursor?) {
    const params = new HttpParams()
      .set('companyId', search.companyId)
      .set('token', search.token)
      .set('cursor', cursor || '')
      .set('count', search.count ? search.count.toString() : '');

    console.debug('Store integrations/invoices/listUnpaid called with', params);

    return firstValueFrom(this.requestAsObservable('', 'GET', 'integrations/invoices/listUnpaid', params));
  }

  getInvoice(invoiceId, companyId, token) {
    const params = new HttpParams()
      .set('companyId', companyId)
      .set('token', token);

    console.debug('Store integrations/invoice/get called with', params);

    return firstValueFrom(this.requestAsObservable('', 'GET', `integrations/invoice/${invoiceId}`, params));
  }

  updateInvoice(invoice, companyId, token) {
    var fields = _.pick(invoice, this.INVOICE_WRITABLE_FIELDS);
    const params = new HttpParams()
      .set('companyId', companyId)
      .set('invoiceId', invoice.id)
      .set('token', token)
      .set('data', JSON.stringify(fields));

    console.debug('Store integrations/invoices/put called with', params);

    return firstValueFrom(this.requestAsObservable('', 'PUT', 'integrations/invoices/put', params));
  }

  getInvoicePdf(invoiceId, companyId, token) {
    const params = new HttpParams()
      .set('companyId', companyId)
      .set('invoiceId', invoiceId)
      .set('token', token);

    console.debug('Store integrations/invoices/getPdf called with', params);

    return firstValueFrom(this.requestAsObservable('', 'GET', 'integrations/invoices/getPdf', params));
  }

  getCreditCards(search, cursor?) {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId())
      .set('cursor', cursor || '')
      .set('count', search.count ? search.count.toString() : '');

    console.debug('Store integrations/cards/list called with', params);

    return firstValueFrom(this.requestAsObservable('', 'GET', 'integrations/cards/list', params));
  }

  preparePaymentSource(paymentMethodId) {
    const params = new HttpParams()
      .set('paymentMethodId', paymentMethodId)
      .set('companyId', this.companyStateService.getSelectedCompanyId());

    console.debug('Store integrations/paymentSource/prepare called with', params);

    return firstValueFrom(this.requestAsObservable('', 'POST', 'integrations/paymentSource/prepare', params));
  }

  addPaymentSource(setupIntentId) {
    const params = new HttpParams()
      .set('setupIntentId', setupIntentId)
      .set('companyId', this.companyStateService.getSelectedCompanyId());

    console.debug('Store integrations/paymentSource/add called with', params);

    return firstValueFrom(this.requestAsObservable('', 'POST', 'integrations/paymentSource', params));
  }

  deletePaymentSource(paymentSourceId) {
    const params = new HttpParams()
      .set('paymentSourceId', paymentSourceId)
      .set('companyId', this.companyStateService.getSelectedCompanyId());

    console.debug('Store integrations/paymentSource/delete called with', params);

    return firstValueFrom(this.requestAsObservable('', 'DELETE', 'integrations/paymentSource', params));
  }

}
